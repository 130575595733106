import { useTranslation } from 'react-i18next';
import React from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

// Pages
import Techstack from "./Techstack";
import Toolstack from "./Toolstack";
import Aboutcard from "./AboutCard";
import AboutSectors from "./AboutSectors";


import referanslar2 from "../../Assets/referanslar2.jpg";
import aboutuslabelphoto1 from "../../Assets/aboutuslabelphoto1.jpg";
import aboutuslabelphoto2 from "../../Assets/aboutuslabelphoto2.jpg";
import aboutStartPageImage from "../..//Assets/weusstartpageimg.jpg"

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // diğer efektleri de seçebilirsiniz

function About() {
  const { t } = useTranslation();
  return (
    <Container fluid className="about-section">

      <HelmetProvider>
        <Helmet>
          <title>{t("AboutPageHelmetTitle")}</title>
          <meta name="description" content={t("AboutPageHelmetDesc")}></meta>
        </Helmet>
      </HelmetProvider>


      <Container fluid style={{ padding: "0" }}>

        <Row className="startPageImgs">
          <div className="aboutStartPageParallax">
            <p className="aboutPageStartPageTitle">{t("aboutPageStartPageTitle")} <br /> MELINTERIORDESİGN<sup>®</sup> </p>
          </div>
        </Row>

        {/* <Row className="startPageImgs">
          <div className="aboutStartPageParallax">
            <img src={aboutStartPageImage}/>
            <p className="aboutPageStartPageTitle">{t("aboutPageStartPageTitle")} <br /> MELINTERIORDESİGN<sup>®</sup> </p>
          </div>
        </Row> */}

        <Row className="aboutPageAboutRow">
          <Col
            className="aboutPageAboutTextArea"
            md={12}
            style={{

            }}
          >
            <Aboutcard />
          </Col>


        </Row>

        {/* <div style={{ height: "80vh" }}>
          <LazyLoadImage style={{ objectFit: "cover" }} width={"100%"} height={"100%"} src={aboutuslabelphoto1} alt='Mimari çalışanlar ofiste'/>
        </div> */}


        <AboutSectors />

        <div className="aboutpageparallax1 textFont">
          <p className="aboutPageParallaxText1"> {t("aboutPageParallaxText1")}</p>
        </div>


        <h1 className="project-heading profApplicationTitle mt-5">
          {t("aboutPageProfApplicationTitle1")} <strong className="purple">{t("aboutPageProfApplicationTitle2")} </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          {t("aboutPageWidgetApplicationTitle1")} <strong className="purple">{t("aboutPageWidgetApplicationTitle2")} </strong>
        </h1>

        <Toolstack />

      </Container>

      {/* <div style={{ height: "80vh" }}>
        <LazyLoadImage style={{ objectFit: "cover" }} width={"100%"} height={"100%"} src={aboutuslabelphoto2} alt='Mimari tasarım proje çizimi'/>
      </div> */}

      <Row style={{ paddingBottom: "30px", paddingTop: "60px", position: "relative", backgroundColor: "white" }}>
        <h1>{t("aboutPageReferancesTitle")}</h1>
        <Container className="containerImage">
          <div>
            <LazyLoadImage alt='boomoon restaurant, uğur okulları, van kahvaltı sarayı, torian atölye cafe, hill boutique, yda center, ilona konutları, star yağcılar treyler, staff global, vıg, dbn inşaat, piyap inşaat, arkas holding, otokent ankara, huminger, meva villaları, alpha bautechnik, ege dizayn, gazsel elektromekanik a.ş, pasifik eurasia, beytepe 1923, rafine incek, referanslar fotoğrafı'
             width={"100%"} height={"510px"} style={{ width: "100%", height: "auto", border: "0", aspectRatio: "auto 1200/510" }} src={referanslar2} />
          </div>
        </Container>

      </Row>


    </Container>
  );
}

export default About;
