import { useTranslation } from 'react-i18next';
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

// Pages
import ProjectCard from "./ProjectCards";


// Title images
import bahcelievlerKonutTitlePhoto from "./img/bahcelievlerKonutTitlePhoto.jpg";
import dbnInsaatOfficeTitlePhoto from "./img/dbnInsaatOfficeTitlePhoto.jpg";
import hillBoutiqueTitlePhoto from "./img/hillBoutiqueTitlePhoto.jpg";
import ilonaKonutlarıTitlePhoto from "./img/ilonaKonutlarıTitlePhoto.jpg";
import mevaVillalariTitlePhoto from "./img/mevaVillalariTitlePhoto.jpg";
import otokentOtoEkspertizTitlePhoto from "./img/otokentOtoEkspertizTitlePhoto.jpg";
import vanKahvaltiTitlePhoto from "./img/vanKahvaltiTitlePhoto.jpg";
import germanyHukukOfficeTitlePhoto from "./img/germanyHukukOfficeTitlePhoto.jpg";
import germanyLoftDaireTitlePhoto from "./img/germanyLoftDaireTitlePhoto.jpg";
import coffeeKioskTitlePhoto from "./img/coffeeKioskTitlePhoto.jpg";
import manolyaMobilyaMağazaTitlePhoto from "./img/manolyaMobilyaMağazaTitlePhoto.jpg";
import urlaMustakilKonutTitlePhoto from "./img/urlaMustakilKonutTitlePhoto.jpg";
import yasamkentVillaTitlePhoto from "./img/yasamkentVillaTitlePhoto.jpg";
import vigInternationalTitlePhoto from "./img/vigInternationalTitlePhoto.jpg";
import piyapInsaatTitlePhoto from "./img/piyapInsaatTitlePhoto.jpg";
import pasifikEurasiaTitlePhoto from "./img/pasifikEurasiaTitlePhoto.jpg";
import normTechnologyTitlePhoto from "./img/normTechnologyTitlePhoto.jpg";
import gazselElektromekanikTitlePhoto from "./img/gazselElektromekanikTitlePhoto.jpg";
import arkasHoldingTitlePhoto from "./img/arkasHoldingTitlePhoto.jpg";
import incekVillaTitlePhoto from "./img/incekVillaTitlePhoto.jpg";
import isvicreKisBahcesiTitlePhoto from "./img/isvicreKisBahcesiTitlePhoto.jpg";



import projectsData from "./ProjectsData.json";

function Projects() {
  const { t } = useTranslation();

  const images = {
    bahcelievlerKonutTitlePhoto,
    dbnInsaatOfficeTitlePhoto,
    hillBoutiqueTitlePhoto,
    ilonaKonutlarıTitlePhoto,
    mevaVillalariTitlePhoto,
    otokentOtoEkspertizTitlePhoto,
    vanKahvaltiTitlePhoto,
    germanyHukukOfficeTitlePhoto,
    germanyLoftDaireTitlePhoto,
    coffeeKioskTitlePhoto,
    manolyaMobilyaMağazaTitlePhoto,
    urlaMustakilKonutTitlePhoto,
    yasamkentVillaTitlePhoto,
    vigInternationalTitlePhoto,
    piyapInsaatTitlePhoto,
    pasifikEurasiaTitlePhoto,
    normTechnologyTitlePhoto,
    gazselElektromekanikTitlePhoto,
    arkasHoldingTitlePhoto,
    incekVillaTitlePhoto,
    isvicreKisBahcesiTitlePhoto
  };

  return (

    <div>

      <HelmetProvider>
        <div>
          <Helmet>
            <title>{t("projectPageHelmetTitle")}</title>
            <meta name="description" content={t("projectPageHelmetDesc")}></meta>
          </Helmet>
        </div>
      </HelmetProvider>

      <Row className="startPageImgs">
        <div className="projectStartPageParallax">
          <p className="projectPageStartPageTitle">{t("projectPageStartPageTitle")} <br /> MELINTERIORDESİGN<sup>®</sup> </p>
        </div>
      </Row>

      <Container fluid className="project-section">

        <Container>
          <h1 className="project-heading mb-5">
          {t("projectPageProjectsTitle")} <strong className="purple">{t("projectPageProjectsTitle2")} </strong>
          </h1>
          <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
            {projectsData.map((projects) => {
              return (
                <Col key={projects.title} sm={12} md={6} xxl={4} className="project-card">
                  <ProjectCard
                    imgPath={images[projects.titleImageName]}
                    isBlog={false}
                    title={t(projects.name)}
                    description={projects.description}
                    city={projects.city}
                    town={projects.town}
                    name={projects.name}
                  />
                </Col>
              )

            })}



          </Row>
        </Container>
      </Container>

    </div>


  );
}

export default Projects;
